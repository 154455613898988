<template>
  <div class="farm" v-if="show">
    <div class="home" @click="hide()"></div>
    <div class="inner">
      <div class="inner-left">
        <div class="inner-left-top" ref="getheight1">
          <div class="inner-common-head">
            基地概况
          </div>
          <div class="intro-item-container">
            <div class="intro-item">
              <div class="intro-item-con color1">胡集合作社</div>
              <div class="intro-item-tit">基地名称</div>
            </div>
            <div class="intro-item">
              <div class="intro-item-con color4">100亩</div>
              <div class="intro-item-tit">种植面积</div>
            </div>
            <div class="intro-item">
              <div class="intro-item-con color3">100吨</div>
              <div class="intro-item-tit">预计产量</div>
            </div>
            <div class="intro-item">
              <div class="intro-item-con color6">100万元</div>
              <div class="intro-item-tit">预计收益</div>
            </div>
            <div class="intro-item">
              <div class="intro-item-con color5">夏永超</div>
              <div class="intro-item-tit">负责人</div>
            </div>
            <div class="intro-item">
              <div class="intro-item-con color2">王赛赛</div>
              <div class="intro-item-tit">技术员</div>
            </div>
          </div>
        </div>
        <div class="inner-left-top inner-left-bottom" ref="getheight2">
          <div class="inner-common-head">
            物联网设备
          </div>
          <div class="deviceChart">
            <farm-device-chart></farm-device-chart>
          </div>
        </div>
      </div>
      <div class="inner-center">
        <div id="video-container"></div>
      </div>
      <div class="inner-right">
        <div class="inner-left-top">
          <div class="inner-common-head">
            气象墒情实时监控
          </div>
          <div class="intro-item-container mt20">
            <div class="shang-item">
              <i class="iconfont icon-guangzhao"></i>
              <div class="shang-content gz">光照强度:1000lux</div>
            </div>
            <div class="shang-item">
              <i class="iconfont icon-eryanghuatan"></i>
              <div class="shang-content gz">二氧化碳:1500ppm</div>
            </div>
            <div class="shang-item">
              <i class="iconfont icon-kongqiwendu"></i>
              <div class="shang-content gz">空气温度:10℃</div>
            </div>
            <div class="shang-item">
              <i class="iconfont icon-kongqishidu"></i>
              <div class="shang-content gz">空气湿度:80%</div>
            </div>
            <div class="shang-item">
              <i class="iconfont icon-turangwendu"></i>
              <div class="shang-content gz">土壤温度:10℃</div>
            </div>
            <div class="shang-item">
              <i class="iconfont icon-turangshidu"></i>
              <div class="shang-content gz">土壤湿度:80%</div>
            </div>
            <div class="shang-item">
              <i class="iconfont icon-turangdanhanliang"></i>
              <div class="shang-content gz">土壤氮含量:ug/sh</div>
            </div>
            <div class="shang-item">
              <i class="iconfont icon-turanglinhanliang"></i>
              <div class="shang-content gz">土壤磷含量:ug/h</div>
            </div>
            <div class="shang-item">
              <i class="iconfont icon-turangjiahanliang"></i>
              <div class="shang-content gz">土壤钾含量:ug/1</div>
            </div>
            <div class="shang-item">
              <div class="icon"><i class="iconfont icon-fengsu "></i></div>
              <div class="shang-content gz">风速:ug/1</div>
            </div>
            <div class="shang-item">
              <i class="iconfont icon-cb-dapingfengxiang"></i>
              <div class="shang-content gz">风向:ug/1</div>
            </div>
            <div class="shang-item">
              <i class="iconfont icon-jiangyuliang"></i>
              <div class="shang-content gz">降雨量:ug/1</div>
            </div>
          </div>
        </div>
        <div class="inner-left-top inner-left-bottom">
          <div class="inner-common-head">
            数据墒情历史统计
          </div>
          <div class="shang">
            <shang-chart></shang-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EZUIKit from 'ezuikit-js';
import FarmDeviceChart from "@/components/farmDeviceChart";
import ShangChart from "@/components/shangChart";

export default {
  name: "farmBeta",
  components: {ShangChart, FarmDeviceChart},
  props: {
    show: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      player: null,
      // width: document.body.clientWidth - 560 * 2 - 100,
      height: document.body.clientHeight,
      cheight: 400,
    }
  },
  mounted() {

  },
  updated() {
    let that =this;
    this.$nextTick(() => { // 页面渲染完成后的回调
      setTimeout(() => {
        let cheight1 = that.$refs.getheight1.offsetHeight;
        let cheight2 = that.$refs.getheight2.offsetHeight;
        that.player = new EZUIKit.EZUIKitPlayer({
          id: "video-container", // 视频容器ID
          accessToken: "at.c8yk6ir8d9hrublf2cbdk0ld9jgn3goq-4n6peqc5n0-0ih5f5u-unsqgwfuv",
          url: "ezopen://open.ys7.com/G82856946/1.hd.live",
          template: 'theme',//
          autoplay: true,
          plugin: ['talk'],// 加载插件，talk-对讲
          startTalk: () => that.playr.startTalk(),
          stopTalk: () => that.playr.stopTalk(),
          width: '100%',
          height: cheight1 + cheight2 + 35,
        });
        // console.log(this.$refs)  //有
      }, 1);
    })
  },
  methods:{
    hide(){
      this.$emit('hideShow');
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.farm {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20220329;
  background: rgb(0,0,0,.5);
}

.home{
  width: 60px;
  height: 60px;
  background-image: url("/static/image/home.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  right: 50%;
  top: 86px;
  position: absolute;
}

.inner {
  background: rgb(2, 0, 77);
  background-image: url("/static/image/in_bg.png");
  width: 100vw;
  height: 100vh;
  padding-top: 85px;
  display: flex;
  flex: 1;
  /*flex-direction: row;*/
  margin-top:68px;
}

.inner-left {
  /*width: 560px;*/
  height: 100vh;
  margin-left: 30px;
  margin-right: 20px;
}

.inner-right {
  /*width: 560px;*/
  height: 100vh;
  margin-right: 30px;
  margin-left: 20px;
}

.inner-left, .inner-right {
  height: 100vh;
  flex: 0 0 500px;
}

.inner-left-bottom {
  margin-top: 35px;
}

.inner-left-top {
  width: 100%;
  height: 40vh;
  background-image: url("/static/image/bg_left_1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.inner-common-head {
  background-image: url("/static/image/bg_left_txt_bg.png");
  background-position: 100% 100%;
  font-size: 14px;
  color: #19ECFF;
  background-repeat: no-repeat;
  text-align: center;
  height: 38px;
  line-height: 38px;
  width: 211px;
  margin-left: auto;
  margin-right: auto;
}

.inner-center {
  height: 100vh;
  flex: 1;
}

#video-container {
  width: 100%;
  height: 100vh;
}

.intro-item-container {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

.intro-item {
  background-image: url("/static/image/intro_item_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 200px;
  height: 106px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  justify-items: center;
  box-sizing: content-box;
  margin-top: 10px;
  margin-bottom: 10px;
}

.intro-item-tit {
  color: #19ECFF;
}

.intro-item-con {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.color1 {
  color: #1337d1;
}

.color2 {
  color: #30ddcf;
}

.color3 {
  color: #ddba30;
}

.color4 {
  color: #30dd8a;
}

.color5 {
  color: #2a6afc;
}

.color6 {
  color: #fd712d;
}

.deviceChart {
  width: 500px;
  height: 400px;
}

.shang {
  margin-top: 40px;
  width: 500px;
  height: 400px;
}

.shang-item {
  width: 130px;
  color: #30dd8a;
  text-align: center;
  margin-top:18px;
}

.shang-item i {
  font-size: 70px;
}

.mt20{
  margin-top: 10px;
}

@-webkit-keyframes animal {
  0%{
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100%{
    transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

.icon{
  -webkit-animation: animal 1.6s infinite linear ;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
}



</style>