<template>
  <div class="head-dsj">&nbsp;</div>
</template>

<script>
export default {
  name: "headDsj"
}
</script>

<style scoped>
  .head-dsj{
    background-image: url("/static/image/dsj-head.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 78px;
    text-align: center;
    font-size: 40px;
    line-height: 78px;
    color: #3ab8e7;
  }
</style>
