import {createApp} from 'vue'
import App from './App.vue'
import * as echarts from 'echarts';
// 在入口文件中引入基本样式
import './assets/reset.css'
import './assets/border.css'
import './assets/iconfont.css'
import { s3Layer } from 'vue3-layer';
import 'vue3-layer/dist/s3Layer.css';
const app = createApp(App);
app.config.globalProperties.echarts = echarts;
app.component('s3-layer', s3Layer);
app.mount('#app');
