<template>
  <div id="farm_device_chart" ref="myRefDeviceFarm" class="chart-box"/>
</template>
<script lang="js">
import {defineComponent,  ref, onMounted} from 'vue';
import * as echarts from "echarts";

export default defineComponent({
  name: 'farmDeviceChart',
  setup() {
    // const {ctx} = getCurrentInstance();
    const myRefDeviceFarm = ref(null);
    // const colors = ['#5470C6', '#EE6666'];
    const option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        bottom: '1%',
        left: 'center',
        textStyle: {
          /*color: (params) => {
            return colors[params.dataIndex];
          }*/
          color: "#3ab8e7",
        }
      },

      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['0%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: 1048, name: '气象站' },
            { value: 735, name: '摄像头' },
            { value: 580, name: '杀虫灯' },
            { value: 484, name: '孢子' },
            { value: 300, name: '虫情' }
          ]
        }
      ]
    };
    const initT = () => {
      const myChart = echarts.init(document.getElementById('farm_device_chart'));
      console.log(myChart);
      myChart.setOption(option);
    };

    onMounted(() => {
      initT();
    });
    return {
      myRefDeviceFarm
    };
  }
});
</script>

<style lang="css" scoped>

.chart-box {
  width: 100%;
  height: 100%;
  /*background: red;*/
}
</style>

