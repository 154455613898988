<template>
  <div class="img_container">
    <img src="http://39.96.170.117:89/photos/20200603/7190923481_1_202063171548.jpeg" />
  </div>
</template>

<script>

export default {
  name: "CarGallery",
  components: [],
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {}
  }
}
</script>
<style scoped>
.seamless-warp {
  height: 20px;
  overflow: hidden;
}
.img_container{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
img{
  width: 80%;
  height: 70%;
}
</style>



