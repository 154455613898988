<template>
  <TMap></TMap>

</template>

<script>
import TMap from './components/TMap.vue'
export default {
  name: 'App',
  components: {
    TMap
    // HelloWorld
  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
}
</style>
