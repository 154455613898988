<template>
  <div id="price_chart" ref="myRefPrice" class="chart-box"/>
</template>
<script lang="js">
import {defineComponent,  ref, onMounted} from 'vue';
import * as echarts from "echarts";

export default defineComponent({
  name: 'priceChart',
  setup() {
    // const {ctx} = getCurrentInstance();
    const myRefPrice = ref(null);
    // const colors = ['#5470C6', '#EE6666'];
    const option = {
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['06-01', '06-02', '06-03', '06-04', '06-05', '06-06', '06-07'],
        axisLine: {
          show: false,
          lineStyle: {
            //设置y轴字体颜色
            color: "#3ab8e7",
          }
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
          lineStyle: {
            //设置y轴字体颜色
            color: "#3ab8e7",
          }
        }
      },
      series: [
        {
          data: [820, 932, 901, 934, 1290, 1330, 1320],
          type: 'line',
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgb(128, 255, 165)'
              },
              {
                offset: 1,
                color: 'rgb(1, 191, 236)'
              }
            ])
          }
        }
      ]
    };
    const initT = () => {
      const myChart = echarts.init(document.getElementById('price_chart'));
      console.log(myChart);
      myChart.setOption(option);
    };

    onMounted(() => {
      initT();
    });
    return {
      myRefPrice
    };
  }
});
</script>

<style lang="css" scoped>

.chart-box {
  width: 100%;
  height: 100%;
  /*background: red;*/
}
</style>

