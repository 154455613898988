<template>
  <div id="shang_chart" ref="myRefShang" class="chart-box"/>
</template>
<script lang="js">
import {defineComponent,  ref, onMounted} from 'vue';
import * as echarts from "echarts";

export default defineComponent({
  name: 'shangChart',
  setup() {
    // const {ctx} = getCurrentInstance();
    const myRefShang = ref(null);
    // const colors = ['#5470C6', '#EE6666'];
    const option = {
      title: {
        text: 'Stacked Line',
        show:false
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['空气温度', '空气湿度', '光照', '二氧化碳', '土壤温度', '土壤湿度', '氮', '磷', '钾'],
        textStyle: {
          color: "#3ab8e7",
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        show:false,
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        axisLine: {
          lineStyle: {
            //设置y轴字体颜色
            color: "#3ab8e7",
          }
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            //设置y轴字体颜色
            color: "#3ab8e7",
          }
        },
      },
      series: [
        {
          name: '空气温度',
          type: 'line',
          stack: 'Total',
          data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
          name: '空气湿度',
          type: 'line',
          stack: 'Total',
          data: [220, 182, 191, 234, 290, 330, 310]
        },
        {
          name: '光照',
          type: 'line',
          stack: 'Total',
          data: [150, 232, 201, 154, 190, 330, 410]
        },
        {
          name: '二氧化碳',
          type: 'line',
          stack: 'Total',
          data: [320, 332, 301, 334, 390, 330, 320]
        },
        {
          name: '土壤温度',
          type: 'line',
          stack: 'Total',
          data: [820, 932, 901, 934, 1290, 1330, 1320]
        },
        {
          name: '土壤湿度',
          type: 'line',
          stack: 'Total',
          data: [820, 932, 901, 934, 1290, 1330, 1320]
        },
        {
          name: '氮',
          type: 'line',
          stack: 'Total',
          data: [820, 932, 901, 934, 1290, 1330, 1320]
        },
        {
          name: '磷',
          type: 'line',
          stack: 'Total',
          data: [820, 932, 901, 934, 1290, 1330, 1320]
        },
        {
          name: '钾',
          type: 'line',
          stack: 'Total',
          data: [820, 932, 901, 934, 1290, 1330, 1320]
        }
      ]
    };
    const initT = () => {
      const myChart = echarts.init(document.getElementById('shang_chart'));
      console.log(myChart);
      myChart.setOption(option);
    };

    onMounted(() => {
      initT();
    });
    return {
      myRefShang
    };
  }
});
</script>

<style lang="css" scoped>

.chart-box {
  width: 100%;
  height: 100%;
  /*background: red;*/
}
</style>

