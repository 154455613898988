<template>
  <h5 class="title">{{ title }}</h5>
</template>

<script>
export default {
  name: "HeadTitle",
  props: {
    title: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped>
  .title{
    color: #afe0fe;
    font-size: 20px;
    width: 100%;
    display: block;
    text-align: center;
  }
</style>
