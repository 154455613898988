<template>
  <div class="gaikuang">
    <div class="item">
      <img :src="src">
      <div class="item-con">
        <div class="item-content">{{ content }}</div>
        <div class="item-title">{{ title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntroItems",
  props: {
    image: {
      type: String,
      default: ""
    }, title: {
      type: String,
      default: ""
    }, content: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      src: require("../../static/image/intro-icon.png")
    }
  }
}
</script>

<style scoped>
.item {
  /*background-image: url("/static/image/item-bg.png");*/
  background-image: url("/static/image/left-item-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 30px;
  /*border-radius: 10px;*/
  border-radius: 0;
  background-size: 100% 100%;
}

.item img {
  width: 65px;
  height: 62px;
  margin-left: 20px;
  margin-top: 18px;
}

.item-content {
  font-size: 19px;
  color: #E6E9EB;
  width: 100px;
  align-items: center;
}

.item-title {
  font-size: 14px;
  color: #1A88A6;
  width: 100px;
  margin-top:12px;
}

.item-con {
  display: flex;
  width: 100px;
  justify-items: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 20px;
}
</style>
