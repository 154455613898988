<template>
  <div id="mycharts" ref="myRef" class="chart-box"/>
</template>
<script lang="js">
import {defineComponent, ref, onMounted} from 'vue';
import * as echarts from "echarts";
const colorList = [
  "#1890ff",
  "#52c41a",
  "#faad14",
  "#f5222d",
  "#1DA57A",
  "#d9d9d9",
];
const category = ['合作社一', '合作社二', '合作社三', '合作社四', '合作社五'];
const barData = [3100, 2142, 1218, 581, 431];
const option = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  grid: {
    left: '4%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    show:false,
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    }
  },
  yAxis: {
    type: 'category',
    nameGap:15,
    position:"left",
    data: category,
    splitLine: {show: false},
    axisLine: {
      show: false,
      lineStyle: {
        //设置y轴字体颜色
        color: "#3ab8e7",
      }
    },
    axisLabel: {
      // offset:[10,20],
      // inside: true,
      margin: 10 //与y轴最左边风格线的边距
    },
    axisTick: {
      show: false
    },
    nameTextStyle: {
      fontSize: 15,
    }
  },
  series: [
    {
      name: '作业面积（亩）',
      type: 'bar',
      data: barData,
      barWidth: 14,
      barGap: 10,
      smooth: true,
      label: {
        normal: {
          show: true,
          position: 'right',
          offset: [8, 0],
          textStyle: {
            color: '#F68300',
            fontSize: 13
          }
        }
      },
      itemStyle: {
        emphasis: {
          barBorderRadius: 7
        },
        normal: {
          barBorderRadius: 7,
          color: (params) => {
            return new echarts.graphic.LinearGradient(
                0, 0, 1, 0,
                [
                  {offset: 0, color: colorList[params.dataIndex]},
                  {offset: 1, color: colorList[params.dataIndex]}
                ]
            );
          }
        }
      }
    }
  ]
};


export default defineComponent({
  name: 'growChart',
  setup() {
    // const {ctx} = getCurrentInstance();
    const myRef = ref(null);

    const initT = () => {
      const myChart = echarts.init(document.getElementById('mycharts'));
      console.log(myChart);
      myChart.setOption(option);
    };

    onMounted(() => {
      initT();
    });
    return {
      myRef
    };
  }
});
</script>

<style lang="css" scoped>

.chart-box {
  width: 100%;
  height: 100%;
  /*background: red;*/
}
</style>

