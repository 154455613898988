<template>
  <div class="hzs">
    <ul>
      <li class="head">
        <div class="name">合作社名称</div>
        <div class="man">联系人</div>
        <div class="tel">联系电话</div>
        <div class="area">亩数</div>
      </li>
      <li>
        <div class="name">合作社一</div>
        <div class="man">徐淼</div>
        <div class="tel">15888888888</div>
        <div class="area">13000</div>
      </li>
      <li>
        <div class="name">合作社一</div>
        <div class="man">徐淼</div>
        <div class="tel">15888888888</div>
        <div class="area">13000</div>
      </li>
      <li>
        <div class="name">合作社一</div>
        <div class="man">徐淼</div>
        <div class="tel">15888888888</div>
        <div class="area">13000</div>
      </li>
      <li>
        <div class="name">合作社一</div>
        <div class="man">徐淼</div>
        <div class="tel">15888888888</div>
        <div class="area">13000</div>
      </li>

    </ul>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      // 实时动态文字滚动

    }
  }
}
</script>
<style scoped>
.hzs{
  margin-top:20px;
}
li{
  display: flex;
  font-size: 16px;
  color: rgb(3,169,255);
  height: 44px;
  line-height: 44px;
}
.head{
  font-weight: bold;
}
.name{
  width: 40%;
}
.man{
  width: 20%;
}
.tel{
  width: 30%;
}
.area{
  width: 10%;
}
</style>



